<template>
  <div class="system-messages">
    <div class="system-list" v-for="infor of infors" :key="infor.id">
      <div class="list-time"><span>{{infor.year}}</span> {{infor.md}}</div>
      <div class="list-txet">
        <div class="text-title">{{infor.title}}</div>
        <div class="text-main">{{infor.content}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'system-messages',
    data() {
      return {
        infors: [],
        paging: {
          type: 1,
          page: 1,
          rows: 10,
          last: false
        }
      }
    },
    created() {
      this.querySystemInfor()
    },
    methods: {
      querySystemInfor(){
        let _this = this;
        if (_this.paging.last){
          return;
        }
        let params = {
          type: _this.paging.type,
          page: _this.paging.page,
          rows: _this.paging.rows
        };
        _this.$api.infor.queryInforList(params).then(res => {
          if(res.data){
            res.data.data.forEach((data, i) =>{
              data.year = data.send_time.substr(0,4);
              data.md = data.send_time.substr(5, 5);
              _this.infors.push(data);
            });
            _this.paging.last = (_this.paging.page >= res.data.paging.pages);
            if (!_this.paging.last){
              _this.paging.page++
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.system-messages {
  background-color: #fff;
  position: relative;
  height: 796px;
  overflow: hidden;
  .system-list {
    background: #ffffff;
    padding: 13px 15px;
    margin: 15px 1px;
    border-radius: 8px;
    position: relative;
    @include box-shadow;
    @include w-flex;

    .list-time {
      color: #666666;
      font-size: 12px;
      margin-right: 16px;
      min-width: 12%;

      span {
        font-size: 15px;
        color: #333333;
      }
    }

    .list-txet {
      color: #666666;
      font-size: 12px;

      .text-title {
        font-size: 16px;
        line-height: 16px;
        color: #444444;
      }

      .text-main {
        margin-top: 8px;
        font-size: 14px;
      }
    }
  }
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
</style>
